var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _c(
        "el-card",
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 6 } }, [_c("location")], 1),
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _c(
                    "el-form",
                    { attrs: { inline: "", align: "right" } },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mgb-0" },
                        [
                          _c("el-input", {
                            staticClass: "mgb-0",
                            attrs: {
                              placeholder: _vm.$t(
                                "lang_please_enter_the_process_title"
                              ),
                              "suffix-icon": "el-icon-search",
                              clearable: "",
                            },
                            model: {
                              value: _vm.searchData.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchData, "title", $$v)
                              },
                              expression: "searchData.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { staticClass: "mgb-0" },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 2 } },
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "bottom",
                                    width: "800",
                                    trigger: "manual",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        type: "primary",
                                      },
                                      on: { click: _vm.searchList },
                                      slot: "reference",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("lang_search")) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "task-main", attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { staticStyle: { height: "100%" }, attrs: { span: 5 } },
            [
              _c(
                "div",
                {
                  staticClass: "task-main-left",
                  staticStyle: { height: "100%" },
                },
                [
                  _c("flow-application-type-tree", {
                    on: { "node-click": _vm.handleNodeClick },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-col",
            { staticStyle: { height: "100%" }, attrs: { span: 19 } },
            [
              _c(
                "div",
                {
                  staticClass: "task-main-right",
                  staticStyle: { height: "100%" },
                },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.tableLoading,
                          expression: "tableLoading",
                        },
                      ],
                      staticClass: "sj-table",
                      attrs: {
                        border: "",
                        fit: true,
                        data: _vm.tableList,
                        "show-overflow-tooltip": true,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: _vm.$t("lang_serial_number"),
                          align: "center",
                          width: "150",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_title_"),
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.endDate
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "4px",
                                          cursor: "pointer",
                                          color: "#409eff",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "【" +
                                            _vm._s(_vm.$t("lang_end")) +
                                            "】"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "span",
                                  {
                                    staticClass: "process-inst-title",
                                    staticStyle: {
                                      "margin-left": "4px",
                                      cursor: "pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.approve(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(scope.row.title) + " ")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_process_type"),
                          prop: "processName",
                          align: "center",
                          "show-overflow-tooltip": "",
                          width: "200",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_handover_node"),
                          prop: "nodeName",
                          align: "center",
                          "show-overflow-tooltip": "",
                          width: "200",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_transferor"),
                          prop: "delegateUserName",
                          align: "center",
                          "show-overflow-tooltip": "",
                          width: "200",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_transfer_time"),
                          align: "center",
                          width: "200",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "10px" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatterDateTimestamp(
                                          scope.row.delegateDate
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c("el-pagination", {
                    staticClass: "sj-pagination",
                    attrs: {
                      "current-page": _vm.pageIndex,
                      "page-sizes": [10, 15, 20, 50],
                      "page-size": _vm.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.pageTotal,
                    },
                    on: {
                      "size-change": _vm.changePageSize,
                      "current-change": _vm.changePage,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }